import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Riddle = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>riddle (mini project #6)</h2>
        <p>Build a small web experience with Express server. Create a simple form of a riddle in which the user "unlocks" pages, by solving or guessing the riddle.</p>
        <p>Make use of the techniques reviewed in Lab #8, specifically multiple express routes and conditional responses.</p>
        <br></br>
        <p>It the next Lab, we will learn how to "deploy" the server. After that you will have a live link to submit.</p>
        <br></br>
        <p>when you are done:</p>
        <p>1. push your new project to your GitHub repositoy and deploy it on Glitch.</p>
        <p>2. in Glitch, find the Live link to the project.</p>
        <p>3. Link your room to the Riddle</p>
      </MiniLayout>
    </Layout>
  )
}



export default Riddle
